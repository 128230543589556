import { useState, useEffect } from 'react';
import { useFeatureToggles } from '../../feature-toggles';
import { moduleClientFactory } from '../../api-service/module-client-factory';

export function useRestrictedAiFeature(featureName: string) {
  const [isRestrictedAiFeatureEnabled, setIsAiFeatureEnabled] = useState<boolean>(false);
  const { isFeatureEnabled } = useFeatureToggles();
  const isFeatureToggleEnabled = isFeatureEnabled(featureName);

  useEffect(() => {
    const checkUserAuthorization = async () => {
      const client = moduleClientFactory.createAiSearchClient();
      const response = await client.getIsAiFeatureEnabled({ featureName });
      if (response?.isEnabled) {
        setIsAiFeatureEnabled(true);
      }
    };
    if (isFeatureToggleEnabled) {
      checkUserAuthorization();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureName, isFeatureToggleEnabled]);

  return { isRestrictedAiFeatureEnabled };
}
