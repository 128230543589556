export const citizenAreaMenuName = 'Arbejdsområde Borger';
export const citizenAreaSubMenuName = 'Borgere';
export const citizenAreaPathName = 'citizen';

export const citizenDetailsTabPathName = 'details';
export const citizenDetailsTabName = 'Borgeroverblik';
export const citizenPersonContactPathName = 'citizen-person-contact/:contactId?';
export const citizenPersonContactPathNameWithoutId = 'citizen-person-contact';

export const citizensOverviewAreaPathName = 'citizens';
export const citizenCasesOverviewAreaPathName = 'citizens/cases';
export const citizenTasksOverviewAreaPathName = 'citizens/tasks';
export const citizenCrowdLetterPathName = 'citizens/crowd-letter';
export const citizenReferralAreaPathName = 'referral';
export const interviewTabPathName = 'interview';
export const citizenInterviewPath = 'citizeninterview';
export const citizenCrowdBookingPath = 'crowd-booking';
export const interviewTabLabel = 'Samtaler';
export const unemploymentTabPathName = 'unemploymentbenefits';
export const unemploymentTabLabel = 'A-kasse';
export const communicationTabPathName = 'communication';
export const communicationTabLabel = 'Kommunikation';
export const changeLogTabPathName = 'history';
export const changeLogTabLabel = 'Historik';
export const changeLogNewTabPathName = 'history-new';
export const changeLogNewTabLabel = 'Ny historik';
export const cvJobsearchTabPathName = 'cvjobsearch';
export const cvJobsearchTabLabel = 'CV & Jobsøgning';
export const interviewPreparationActivityPathName = 'interview-preparation-activity';
export const interviewPreparationSicknessPathName = 'interview-preparation-sickness';
export const interviewPreparationJobReadyPathName = 'interview-preparation-job-ready';
export const guideInterviewDeadline = 'guide-interview-deadline';
export const guideInterviewPersonCategory = 'guide-interview-person-category';
export const guideConductInterview = 'guide-conduct-interview';
export const bookingPath = 'booking';
export const bookInterviewPath = 'guide-book-interview';
export const editBookInterviewPath = `${bookInterviewPath}/edit`;
export const citizenInterviewBookingPath = 'citizeninterviewbooking';
export const interviewIdPath = 'interviewid';
export const bookingIdPath = 'bookingid';
export const citizenParameter = ':citizenId';
export const citizenGrantPathName = 'grant';
export const citizenAdultAssessmentPathName = 'vum';

export const journalTabPathName = 'journal';
export const journalDocumentWizardPathName = 'document/create';
export const journalDocumentShowDocument = 'document/preview';
export const journalDocumentsByCase = 'documents/bycase';
export const journalTabLabel = 'Journal & Dokumenter';

export const planAndActivitiesTabPathName = 'plan'; // ToDo_Guru: maybe this should be 'planAndActivities'
export const planAndActivitiesView = 'view';
export const planAndActivitiesTabLabel = 'Plan & Aktiviteter'; // ToDo_Guru: PO review
export const planAndActivitiesDownloadPdfPathName = 'pdf/:versionIdentifier';
export const planAndActivitiesDownloadPrintPreviewPdfPathName = 'print-preview';
export const planAndActivitiesContractEditPathName = 'contract/edit';
export const planAndActivitiesJobSearchEditPathName = 'jobsearch/edit';
export const planAndActivitiesEducationPlanEditPathName = 'education-plan/edit';
export const planAndActivitiesPlanEditPathName = 'edit';
export const planAndActivitiesEducationImpositionGoalsModal = 'education-imposition-goals-modal';
export const planAndActivitiesEducationImpositionEducationModal = 'education-imposition-educations-modal';
export const planAndActivitiesActivationLevel = 'activation-level';

export const journalReportPathName = 'report';
export const journalFileDownloadPathName = 'report/:journalReportId/files/:fileId';
export const journalNoteAttachmentDownloadPathName = 'note/files/:fileId';
export const documentAttachmentDownloadPathName = 'document/files/:fileId';
export const documentArchiveDownloadPathName = 'note/files/documentarchive';
export const journalNotePathName = 'note';
export const journalNoteView = 'view';
export const journalNoteCreate = 'create';
export const citizenJournalNote = 'citizenjournalnote';
export const activityTabPathName = 'activities';
export const activityTabLabel = 'Aktiviteter';
export const citizenAvailabilityTabLabel = 'Rådighed & Fritagelser';
export const citizenAvailabilityTabPathName = 'citizen-availability';
export const contactGroupCreatePath = 'contact-group/create';
export const contactGroupViewEditPath = 'contact-group/view/:contactGroupId';

export const negativeEventCreatePath = 'negative-event/create';
export const negativeEventViewEditPath = 'negative-event/edit/:negativeEventId';
export const availabilityAssessmentCreatePath = 'availability-assessment/create/:negativeEventId';
export const availabilityAssessmentViewEditPath = 'availability-assessment/view/:availabilityAssessmentId';
export const absenceCreatePath = 'absence/create';
export const absenceEditPath = 'absence/edit/:absenceId';
export const absenceHistoryPath = 'absence-history';
export const activityHistoryPath = 'activity-history';

export const healthLimitationCreatePath = 'health-limitation/create';
export const healthLimitationViewEditPath = 'health-limitation/edit';

export const socialIntegrationCreateHoiGuidePath = 'social-integration/create-hoi-guide';

export const joblogDetailPath = 'joblog/:joblogId';

export const jobReferralDetailPath = 'jobreferral/:id?';

export const createCasePathName = 'create-case';
export const editCasePathName = 'edit-case/:caseId';

export const caseWorkerPathName = 'case-worker/:associatedCaseWorkerId?';

export const citizenLetterGuide = 'guide-letter';
export const citizenFormGuide = 'guide-form';

export const unemploymentEnrollmentCreatePath = 'enrollment/enroll';
export const unemploymentEnrollmentCancelPath = 'enrollment/cancel';
export const unemploymentEnrollmentViewPath = 'enrollment/view/:enrollmentId';

export const particularitiesPath = 'particularities';

export const temporaryAddressPath = 'temporary-address';

export const missingParticipationCrmTableViewPathName = 'missing-participation-crm-table-view/:caseId';
export const resumedWorkCrmTableViewPathName = 'resumed-work-crm-table-view/:caseId';
export const benefitExtensionCrmTableViewPathName = 'benefit-extension-crm-table-view/:caseId';

export const partialRecoveryPathName = 'case/:caseId/partial-recovery/:partialRecoveryId?';

export const expectedJobResumption = 'case/:caseId/expected-job-resumption/:expectedJobResumptionId?';

export const missingParticipationPathName = 'case/:caseId/missing-participation/:missingParticipationId?';

export const benefitExtensionPathName = 'case/:caseId/benefit-extension/:benefitExtensionId?';

export const citizenTasksCreatePathName = 'tasks/create';
export const citizenTasksEditPathName = 'tasks/edit/:taskId';

export const citizenRoutingAbsencePathName = 'absence/:absenceId';
export const citizenRoutingNegativeEventPathName = 'negative-event/:negativeEventId';
export const citizenRoutingAssessmentPathName = 'assessment/:assessmentId';
export const illnessAbsencesCrmTableViewPathName = 'illness-absences-crm-table-view/:caseId';

export const integrationDetailsTabPathLabel = 'Asyl & Integration';
export const integrationDetailsTabPathName = 'integration-details';

export const citizenCreateReportGuide = 'create-report';

export const citizenNuphForm = 'nuph/:id?';

export const citizenComments = 'citizencomments';

export const journalReportJobStatus = 'journalReportJobStatus';
