import { CancelToken } from 'axios';
import { isEqual } from 'lodash';
import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { IFeatureTogglesClient } from '../api-service/clients';
import { ErrorZone } from '../components/error-zone';
import { useApiOnRender } from '../hooks/use-api';

export const FeatureToggleRefreshRate = 10 * 60 * 1000;
let reloadInterval: NodeJS.Timeout;

type FeatureToggles = { [key: string]: boolean };

function getFeatureByName(featureName: string, allFeaturetoggles: FeatureToggles | undefined): boolean {
  if (allFeaturetoggles) {
    for (const [key, value] of Object.entries(allFeaturetoggles)) {
      if (key.localeCompare(featureName, undefined, { sensitivity: 'base' }) === 0) {
        return value;
      }
    }
  }
  return false;
}

const storageName = 'feature-toggles';

function getLocalState(): FeatureToggles | undefined {
  let featureToggles: FeatureToggles | undefined;
  const item = localStorage.getItem(storageName);
  try {
    if (item) {
      featureToggles = JSON.parse(item);
    }
  } catch {
    // Do nothing
  }
  return featureToggles;
}

function setLocalState(featureToggles: FeatureToggles) {
  return localStorage.setItem(storageName, JSON.stringify(featureToggles));
}

// Initially use feature toggles saved in localStorage from last time application was run.
// This gives us a good "default" value that is correct 99% of the time, and wont trigger re-render when features are loaded from API.
const globalFeature = { Toggles: getLocalState() };

/**
 * Use this function in any **non** React component where you want to use feature toggle values.
 * Note that this will not be updated automatically when featuretoggles change.
 */
export function isFeatureEnabled<T extends string = string>(featureName: T): boolean {
  return getFeatureByName(featureName, globalFeature.Toggles);
}

export interface IFeatureToggleProvider {
  isFeatureEnabled<T extends string = string>(featureName: T): boolean;
}

interface IWithFeatureTogglesProps {
  features: FeatureToggles;
}

const FeatureContext = React.createContext<FeatureToggles>({} as FeatureToggles);

/**
 * Provides an optional context where feature toggle data is available and refreshed periodically
 * @param props Client used to fetch feature toggle data from backend
 * */
export function WithFeatureTogglesFromApi(props: PropsWithChildren<{ client: IFeatureTogglesClient }>) {
  const { children, client } = props;
  const { features, isError, error } = useFeatureTogglesFromApi(client);

  /**
   * when no feature data is available, we intentionally don't render anything other than an error zone.
   * This makes the entire tree of subcomponents dependent on feature data, and this is by design.
   * Since we plan to use this context very high up in the component tree, the entire JDC will effectively
   * be depending on feature data. Again, this is by design.
   */
  if (features) {
    return <WithFeatureToggles features={features}>{children}</WithFeatureToggles>;
  }

  // the error zone is only shown if no feature data has been available at any time
  return isError ? <ErrorZone error={error} /> : null;
}

/**
 * Provides a hook where feature toggle data is available and refreshed periodically
 * @param client Client used to fetch feature toggle data from backend
 * */
function useFeatureTogglesFromApi(client: IFeatureTogglesClient) {
  // Load feature toggles on load. This is also triggered periodically by timer
  const { result, isReady, isError, error, reload } = useApiOnRender((cancelToken: CancelToken) => client.getAllFeatureToggles(cancelToken), {} as FeatureToggles);

  // We use internal state for feature data here, because don't want to temporarily reset feature-data while API is updating.
  const [featuresState, setFeatureState] = useState<FeatureToggles | undefined>(globalFeature.Toggles);

  useEffect(() => {
    //No statuscode so check on error.message failed with status code 401
    if (isError && reloadInterval && error.message.includes('code 401')) {
      clearInterval(reloadInterval);
    }
  }, [isError]);

  // Trigger an effect each time updated feature-toggles arrive from server
  useEffect(() => {
    const { featureToggles: newFeatureToggles } = result;
    if (!isReady || !newFeatureToggles) {
      return;
    }

    // Compare value with previous data. Only update data if they are different because this would triggers a re-render.
    if (!featuresState || !isEqual(newFeatureToggles, featuresState)) {
      globalFeature.Toggles = newFeatureToggles;
      setFeatureState(newFeatureToggles);
      setLocalState(newFeatureToggles);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, result]);

  if (!reloadInterval) {
    reloadInterval = setInterval(reload, FeatureToggleRefreshRate) as any;
  }

  return { features: featuresState, isError, error };
}

/**
 * Creates a context with available feature toggles. This is decoupled from
 * fetching data from the API, and can be used for testing as well.
 */
export function WithFeatureToggles(props: PropsWithChildren<IWithFeatureTogglesProps>) {
  const { children, features } = props;
  return <FeatureContext.Provider value={features}>{children}</FeatureContext.Provider>;
}

/**
 * Use this hook in any React component where you want to use feature toggle values.
 */
export function useFeatureToggles(): IFeatureToggleProvider {
  const featureContext = useContext(FeatureContext);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const isFeatureEnabled = useCallback(<T extends string = string>(featureName: T) => getFeatureByName(featureName, featureContext), [featureContext]);

  return {
    isFeatureEnabled,
  };
}
