import { AxiosRequestConfig } from 'axios';
import { SecureApiService } from '../../../../api-service';
import { IConfiguration, configuration } from '../../../../configuration';
import { ISearchRequestBody, ISavedQuerySearchRequestBody, ISortColumn } from '../../models/dtos';
import { ISearchField } from './search-field';

export interface ISearchResultColumn {
  name: string;
  type: string; // SearchDataType
  displayName?: string;
  format?: string;
  width?: number;
  uiHint?: string;
  codelistType?: string;
  isSortable?: boolean;
  codeListName?: string;
}

export interface ISearchResponse {
  searchResult: {
    columns?: ISearchResultColumn[];
    embeddedColumns?: ISearchResultColumn[];
    rows: any[];
    resultsPagesCount: number;
    rootResultsCount: number;
    rowsCount: number;
    numberOfResults: number;
    currentPageCount: number;
    universeName: string;
    resultType: string;
    resultTypeName?: string;
    embeddedResultType: string;
    embeddedResultTypeName?: string;
  };
  workListTitle: string;
  sortColumns: ISortColumn[];
  query: any;
  universe: string;
}

export interface IQueryApiService {
  search(data: ISearchRequest, config?: AxiosRequestConfig): Promise<ISearchResponse>;
  searchByQueryId(data: ISavedQuerySearchRequest, config?: AxiosRequestConfig): Promise<ISearchResponse>;
  getSearchSchema(config?: AxiosRequestConfig): Promise<ISearchSchema[]>;
  getSearchFields(universe: string, usePreviewVersion: boolean, config?: AxiosRequestConfig): Promise<ISearchField[]>;
  getDefaultQuery(universe: string, config?: AxiosRequestConfig): Promise<ISearchRequestBody>;
}

export interface IPaging {
  pageNumber: number;
  pageSize: number;
  embeddedPageNumber: number;
  embeddedPageSize: number;
}

export interface ISearchRequest extends IPaging {
  body: ISearchRequestBody;
}
export interface ISavedQuerySearchRequest extends IPaging {
  body: ISavedQuerySearchRequestBody;
}

export interface ISearchSchema {
  name: string;
  displayName: string;
  allowListsAsResultType?: boolean;
}

export interface IResultTypes {
  name: string;
  items: IResultType[];
}

export interface IResultType {
  name: string;
  displayName: string;
}

export class QueryApiService implements IQueryApiService {
  private readonly baseService: SecureApiService = new SecureApiService();
  private readonly configuration: IConfiguration = configuration;

  search = async (data: ISearchRequest, config?: AxiosRequestConfig): Promise<ISearchResponse> => {
    const url = `${this.getApiAddress()}/search`;
    return this.baseService.post<ISearchRequest, ISearchResponse>(url, data, config);
  };

  searchByQueryId = async (data: ISavedQuerySearchRequest, config?: AxiosRequestConfig): Promise<ISearchResponse> => {
    const url = `${this.getApiAddress()}/search/query`;
    return this.baseService.post<ISavedQuerySearchRequest, ISearchResponse>(url, data, config);
  };

  getSearchSchema = async (config?: AxiosRequestConfig): Promise<ISearchSchema[]> => {
    const url = `${this.getApiAddress()}/DocumentSchema/List`;
    return this.baseService.get<undefined, ISearchSchema[]>(url, undefined, config);
  };

  getSearchFields = async (universe: string, usePreviewVersion = false, config?: AxiosRequestConfig): Promise<ISearchField[]> => {
    if (universe) {
      const url = usePreviewVersion ? `${this.getApiAddress()}/DocumentSchema/${universe}/PreviewFields` : `${this.getApiAddress()}/DocumentSchema/${universe}/Fields`;
      return this.baseService.get<undefined, ISearchField[]>(url, undefined, config);
    }
    return Promise.resolve([]);
  };

  getDefaultQuery = async (universe: string, config?: AxiosRequestConfig): Promise<ISearchRequestBody> => {
    if (universe) {
      const url = `${this.getApiAddress()}/DocumentSchema/${universe}/DefaultQuery`;
      return this.baseService.get<undefined, ISearchRequestBody>(url, undefined, config);
    }
    return Promise.resolve({} as ISearchRequestBody);
  };

  private readonly getApiAddress = () => this.configuration.searchBaseAddress;
}
export const queryApiService = new QueryApiService();
