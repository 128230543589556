import * as React from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FilledTextFieldProps } from '@mui/material/TextField';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrochipAi } from '@fortawesome/pro-light-svg-icons';
import { ITextFieldProps } from './text-field-props';
import { TextField } from './text-field';
import { ColorPalette } from '../config';

export interface IMaxLengthTextFieldProps extends ITextFieldProps {
  maxLength: number;
  maxLengthError?: string;
  lazy?: boolean;
  showAiIcon?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      '& span:last-child': {
        marginLeft: 'auto',
      },
    },
  })
);

/*
 * A specialized textbox that displays current length and maximum number of characters as helperText.
 * Note! Textbox is *not* limited to maxLength (allowing paste of long text), but the textbox enters error state.
 */
export function MaxLengthTextField(props: React.PropsWithChildren<IMaxLengthTextFieldProps>) {
  const { maxLength, maxLengthError, helperText, label, showAiIcon, children, ...rest } = props;
  const { value, error } = rest;
  const classes = useStyles();
  const length = value ? (value as string).length : 0;
  const changedProps = {} as Partial<ITextFieldProps>;

  if (length > maxLength && !error) {
    changedProps.error = true;
    changedProps.errorText = `${maxLengthError || 'Teksten indeholder for mange tegn'} (${length}/${maxLength})`;
  }

  const helperTextAlignment = changedProps.error || error || helperText ? 'left' : 'right';

  const FormHelperTextProps: FilledTextFieldProps['FormHelperTextProps'] = {
    style: { textAlign: helperTextAlignment },
  };

  const helperTextComponent = helperText ? (
    <span className={classes.wrapper}>
      <span>{helperText}</span>
      <span>
        {length}/{maxLength}
      </span>
    </span>
  ) : (
    `${length}/${maxLength}`
  );

  return (
    <TextField
      {...rest}
      {...changedProps}
      helperText={helperTextComponent}
      FormHelperTextProps={FormHelperTextProps}
      label={
        showAiIcon ? (
          <Box display="flex" gap={0.5}>
            <Box>{label}</Box>
            <FontAwesomeIcon size="lg" icon={faMicrochipAi} color={ColorPalette.HotAurora} title="Dette felt kan indeholde tekst, der er baseret på AI." />
          </Box>
        ) : (
          label
        )
      }
    >
      {children}
    </TextField>
  );
}
